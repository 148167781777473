import {toPriceStr} from '@/utils';
import React from 'react';
import {Input} from '@rneui/themed';
import {useInnerStyle} from './recharge.hooks';
import {BalanceListItem} from './recharge.service';
import {Trans, useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';

export interface RechargeSelectProps {
  min: number;
  max: number;
  balance: string;
  onChangeBalance: (balance: string) => void;
  balanceList?: BalanceListItem[];
}

const RechargeSelect: React.FC<RechargeSelectProps> = ({
  balance,
  onChangeBalance,
  balanceList,
  max,
}) => {
  const {i18n} = useTranslation();
  const {inputStyle, inputStyles} = useInnerStyle();

  return (
    <div className="bg-card rounded-lg p-3 flex flex-col gap-2">
      <div className="rounded-lg border border-line bg-[var(--BG)] w-full h-12 px-3 items-center flex flex-row">
        <span className="mr-[-0.375rem] text-t3">{globalStore.currency}</span>
        <Input
          containerStyle={[inputStyles.container]}
          inputContainerStyle={inputStyles.inputContainer}
          style={inputStyle}
          errorStyle={inputStyles.error}
          keyboardType="numeric"
          inputMode="numeric"
          value={balance}
          onChangeText={value => {
            if (value && !value.startsWith('0')) {
              if (/^[0-9]+$/.test(value)) {
                if (max > 0) {
                  if (Number(value) <= max) {
                    onChangeBalance(value);
                  }
                } else {
                  if (Number(value) <= 50000) {
                    onChangeBalance(value);
                  }
                }
              } else {
                onChangeBalance(balance || '');
              }
            } else {
              onChangeBalance('');
            }
          }}
          placeholder={i18n.t('withdraw-page.error.addAmount')}
        />
      </div>
      <div className="text-t3 text-xs">
        <Trans
          i18nKey="withdraw-page.label.enterText"
          components={{
            mark: <span className="text-c1" />,
          }}>
          {i18n.t('withdraw-page.label.enterText')}
        </Trans>
      </div>
      <div className="py-2 flex flex-row flex-wrap gap-2">
        {balanceList?.map((bl, index) => (
          <div
            key={index}
            className={`text-t1 text-sm font-bold w-[6.47916rem] h-10 p-2 flex items-center justify-center rounded
            ${
              bl.balance + '' !== balance
                ? 'border border-line bg-[var(--button-small)] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]'
                : 'bg-c1'
            }`}
            onClick={() => onChangeBalance(bl.balance + '')}>
            {toPriceStr(bl.balance, {
              fixed: 0,
              showCurrency: false,
              thousands: true,
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RechargeSelect;
